<template>
  <div>
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="allUsers"
      loading-text="Malumot yuklanmoqda..."
      :page.sync="page"
      single-select
      @click:row="rowClick"
      item-key="id"
      :items-per-page="10"
      class="row-pointer"
      hide-default-footer
      fixed-header
      @page-count="pageCount = $event"
    >
      <template v-slot:[`item.index`]="{ item }">
        {{
          allUsers
            .map(function (x) {
              return x.id
            })
            .indexOf(item.id) + 1
        }}
      </template>
      <template v-slot:[`item.import_date`]="{ item }">
        {{ item.import_date | formatDate }}
      </template>

      <template v-slot:[`item.report_end_date`]="{ item }">
        {{ item.report_end_date | formatDate }}
      </template>

      <template v-slot:[`item.report_start_date`]="{ item }">
        {{ item.report_start_date | formatDate }}
      </template>

      <template v-slot:[`item.report_start_date_remain`]="{ item }">
        {{ item.report_start_date_remain | formatDate }}
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <Action :index="item" />
      </template>
    </v-data-table>
    <div class="text-center py-2">
      <v-pagination
        v-model="page"
        :total-visible="7"
        @input="getPostData"
        :length="pageCount"
      ></v-pagination>
    </div>
    <div>
      <p
        style="font-size: 15px"
        class="
          text-dark
          font-bold
          label label-lg label-light-success label-inline
        "
      >
        Jami Boshlang’ish qiymat:{{ allUsersTotalSumm && numberWithSpaces(allUsersTotalSumm) || 0 }}
      </p>
    </div>
  </div>
</template>
<script>
import Action from './Action'
import { numberWithSpaces } from "@/utils"
export default {
  components: { Action },
  data: () => ({
    counter: 0, // count the clicks
    timer: null, // Needs to be specified here so it can be accessed on both clicks
    selectedId: -1,
    headers: [
      {
        text: '#',
        value: 'index'
      },
      {
        text: 'id',
        value: 'id'
      },
      {
        text: 'Nomi',
        value: 'name'
      },
      {
        text: 'Inventar #',
        value: 'inventar_number'
      },
      {
        text: 'Kirim sanasi',
        value: 'import_date'
      },
      {
        text: "Boshlang'ich narxi",
        value: 'starting_amount'
      },
      {
        text: 'Muddati,Oy',
        value: 'period_usage'
      },
      {
        text: 'Tugash sanasi',
        value: 'report_end_date'
      },
      {
        text: 'Kunlik amortizatsiya',
        value: 'daily_amortization_amount'
      },
      {
        text: 'Assosiy vosita qoldigi',
        value: 'remain_amount_overall'
      },
      {
        text: 'Hisobot davri amortizatsiya',
        value: 'amortization_for_report_period'
      },
      {
        text: 'Hisobot davri boshi',
        value: 'report_start_date'
      },
      {
        text: 'Hisobot boshidagi davri qoldigi',
        value: 'report_start_date_remain'
      },
      {
        text: 'Reg raqam',
        value: 'reg_number'
      },
      {
        text: 'Izoh',
        value: 'comment'
      },
      {
        text: 'Sklad',
        value: 'placement_warehouse_name'
      },
      {
        text: '',
        value: 'action'
      }
    ],
    perPage: 10,
    page: 1,
    sortable: true,
    sortByFormatted: true,
    filterByFormatted: true
  }),
  mask: (val) => {
    if (val !== undefined) {
      return val.toLocaleString('es-US')
    }
  },
  created() {
    this.page = parseInt(this.$route.params.id, 10)
    this.getPostData(this.page)
    
  },
  watch: {
    '$route.params.id': function (id) {
      this.page = parseInt(id, 10)
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    allUsers() {
      const data = this.$store.state.requests.mainassetsNotTaken.results
      if (data !== undefined) {
        data.forEach((element) => {
          // eslint-disable-next-line no-extra-boolean-cast
          if (Boolean(element.starting_amount_new)) {
            element.starting_amount_new =
              element.starting_amount_new.toLocaleString('es-US')
          }
          if (element.daily_amortization_amount) {
            element.daily_amortization_amount =
              element.daily_amortization_amount.toLocaleString('es-US')
          }
          if (element.amortization_for_report_period) {
            element.amortization_for_report_period =
              element.amortization_for_report_period.toLocaleString('es-US')
          }
          if (element.remain_amount_overall) {
            element.remain_amount_overall =
              element.remain_amount_overall.toLocaleString('es-US')
          }
          if (element.report_start_date_remain) {
            element.report_start_date_remain =
              element.report_start_date_remain.toLocaleString('es-US')
          }
        })
      }
      return data
    },
    allUsersTotalSumm() {
      console.log(this.$store.state.requests.mainassetsNotTaken.total_amounts);
      return this.$store.state.requests.mainassetsNotTaken.total_amounts
    },
    pageCount() {
      var data
      if (this.$store.state.requests.mainassetsNotTaken.count !== undefined) {
        data = Math.ceil(
          this.$store.state.requests.mainassetsNotTaken.count / 10
        )
      } else {
        data = 0
      }
      return data
    }
  },
  methods: {
    numberWithSpaces,
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        row.select(false)
      }
    },
    getPostData() {
      if (this.$store.state.requests.filterData.data !== undefined) {
        const asd = {
          id: this.page,
          payload: this.$store.state.requests.filterData.data
        }
        this.$store.dispatch('clientsSearchPerPages', asd)
        if (this.$route.path !== '/allUsers/' + this.page) {
          this.$router.push('/allUsers/' + this.page)
        }
      } else {
        this.$store.dispatch('getmainassetsNotTaken', this.page)
        if (this.$route.path !== '/mainassets/nottaken/' + this.page) {
          this.$router.push('/mainassets/nottaken/' + this.page)
        }
      }
    }
  },
}
</script>
<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}
.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
